import _toConsumableArray from "/opt/build/repo/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/opt/build/repo/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import breakpoints from '../config/breakpoints';
export var generateBreakpointProps = Object.keys(breakpoints).reduce(function (acc, breakpoint) {
  return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, "".concat(breakpoint, "Class"), String));
}, {});
export var breakpointsNames = Object.keys(breakpoints);
export var allBreakpointsNames = ['mobile'].concat(_toConsumableArray(breakpointsNames));
export var breakpointsValues = Object.values(breakpoints);
export var breakpointsArray = Object.keys(breakpoints).reduce(function (acc, breakpoint) {
  return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, breakpoint, parseInt(breakpoints[breakpoint])));
}, {});
export var extractResponsiveValues = function extractResponsiveValues(blok, name) {
  var withoutPrefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return [blok[name]].concat(_toConsumableArray(breakpointsNames.map(function (breakpointName) {
    if (!blok[breakpointName + '_' + name]) {
      return '';
    }

    if (withoutPrefix) {
      return blok[breakpointName + '_' + name];
    }

    return breakpointName + ':' + blok[breakpointName + '_' + name];
  })));
};
export var extractClassValues = function extractClassValues(blok) {
  if (blok["class"]) {
    return [].concat(_toConsumableArray(blok["class"].split(' ')), _toConsumableArray(breakpointsNames.map(function (breakpointName) {
      if (!blok[breakpointName + '_class']) {
        return '';
      }

      return blok[breakpointName + '_class'].split(' ').map(function (className) {
        return breakpointName + ':' + className;
      });
    }))).reduce(function (acc, val) {
      return acc.concat(val);
    }, []);
  } else {
    return [];
  }
};
export var mobileFirstValues = function mobileFirstValues(blok, name) {
  var values = extractResponsiveValues(blok, name, true);
  var value = values[0];
  return values.map(function (_value) {
    if (_value) {
      value = _value;
    }

    return value;
  });
};
export var extractStyleValue = function extractStyleValue(style) {
  return style.trim().split('\n').reduce(function (a, b) {
    var i = b.split(':');
    a[i[0]] = i[1];
    return a;
  }, {});
};
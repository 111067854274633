import { AddressModel, CardModel, CreateCompanyResponse, QuickRegisterModel, RestMethods, UserModel } from '../models';
import { FetchAPI } from '../services/rest.service';
import { EnquiryModel } from '../models/enquiry.model';

export const quickRegisterAPI = (payload: FormData) => {
    return FetchAPI<QuickRegisterModel>(`/users`, RestMethods.Post, { body: payload });
};

export const resendAccountSetupEmailAPI = (payload: FormData) => {
    return FetchAPI<QuickRegisterModel>(`/users/resend_account_setup_email`, RestMethods.Post, { body: payload });
};

export const accountSetupAPI = (payload: FormData) => {
    return FetchAPI<CreateCompanyResponse>(`/account_setup`, RestMethods.Put, { body: payload });
};

export const updateUserAPI = (payload: FormData) => {
    return FetchAPI<UserModel>(`/users/${payload.get('id')}`, RestMethods.Put, { body: payload });
};

export const updateUserWithTokenAPI = (payload: FormData) => {
    return FetchAPI<UserModel>(`/users`, RestMethods.Put, { body: payload });
};

export const addPaymentDetailsAPI = (payload: FormData) => {
    return FetchAPI<CardModel>(`/payments`, RestMethods.Post, { body: payload });
};

export const addAddressAPI = (payload: FormData) => {
    return FetchAPI<AddressModel>(`/address`, RestMethods.Post, { body: payload });
};

export const updateAddressAPI = (payload: FormData) => {
    return FetchAPI<AddressModel>(`/address/${payload.get('id')}`, RestMethods.Put, { body: payload });
};

export const getCountriesAPI = () => fetch('https://restcountries.com/v2/all?fields=name,alpha2Code,translations,flags,callingCodes');

export const getCountryCodesApi = () => fetch('https://countriesnow.space/api/v0.1/countries/codes');

export const submitEnquiryAPI = (payload: any) => FetchAPI<{ enquiry: EnquiryModel }>('/enquiries', RestMethods.Post, {
    body: payload,
    headers: { 'Content-Type': 'application/json' }
});

import { serialize } from 'object-to-formdata';
import {
    accountSetupAPI,
    getCountriesAPI,
    quickRegisterAPI,
    resendAccountSetupEmailAPI,
    submitEnquiryAPI
} from '../../services/registration.api.service';
import { AccountSetupPayload, APIErrorResponse, APIResponse, CountryCode, CreateCompanyResponse, QuickRegisterModel } from '../../models';
import { confirmPaymentAPI } from '../../services/stripe.api.service';
import { PaymentIntent } from '@stripe/stripe-js';
import { EnquiryModel } from '../../models/enquiry.model';
import countries from '../../models/countries.json';

export class AccountSetupFacade {
    static prepareQuickRegisterPayload = (form: QuickRegisterModel): FormData => {
        return serialize({
            user: {
                ...form,
                profile_attributes: {
                    country: form.country
                }
            }
        }, null);
    };

    static quickRegister = (formData: QuickRegisterModel) => {
        return quickRegisterAPI(AccountSetupFacade.prepareQuickRegisterPayload(formData));
    };

    static setupAccount = (formData: AccountSetupPayload) => {
        const payload = serialize({ user: formData.user, otp: formData.otp });
        return accountSetupAPI(payload);
    };

    static addPaymentInfo = (formData: FormData) => {
        //TODO REVISIT-REWATH Will do later
    };

    static updatePaymentInfo = (formData: FormData) => {
        //TODO REVISIT-REWATH Will do later

    };

    static preparePaymentPayload = (formData: FormData): FormData => {
        const data = Object.fromEntries(formData.entries());
        return serialize({
            ...data,
            profile_attributes: {
                ...data
            }
        });
    };

    static getCountries = async () => getCountriesAPI()
        .then(async response => {
            const json = await response.json() as { name: string, alpha2Code: string, translations: { [lang: string]: string }[], flags: { svg: string }, callingCodes: [string] }[];
            return json.map(country => ({
                name: country.name,
                code: country.alpha2Code,
                dial_code: country.callingCodes?.[0]
            }));
        });

    static getCountryCodes = (): Promise<CountryCode[]> => {
        return Promise.resolve(countries)
    }
    static getLocalizedCountryName = (code: string, locale: string) => {
        const country = countries.find(country => country.code === code);
        return country?.translations[locale] ?? country.name ?? code;
    }

    static confirmPayment = async (payload: CreateCompanyResponse): Promise<APIResponse<PaymentIntent> | APIErrorResponse> => {

        //Skip stripe payment confirm if skip_payment is true
        if (payload.skip_payment) {
            return Promise.resolve({ data: { id: null, status: 'succeeded', confirmation_method: 'manual', amount: 0 } as PaymentIntent, error: null });
        }

        const response = await confirmPaymentAPI(payload.payable_code);
        return {
            data: response.paymentIntent,
            error: { errors: response.paymentIntent ? null : [response.error.message] }
        };
    };

    static resendAccountSetupEmail = (email: string) => {
        return resendAccountSetupEmailAPI(serialize({ email }));
    };

    static submitEnquiry = (data: Partial<EnquiryModel>) => {
        return submitEnquiryAPI(JSON.stringify({ enquiry: data }));
    };
}
